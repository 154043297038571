export enum UserRole {
  OWNER = 1,
  MANAGER = 2,
  MEMBER = 3,
}

export const getUserRoleName = (userRole: number): string => {
  const locale = "member.";
  switch (userRole){
    case UserRole.OWNER:
      return `${locale}role1`;
    case UserRole.MANAGER:
      return `${locale}role2`;
    case UserRole.MEMBER:
      return `${locale}role3`;
    default:
      return "";
  }
}
