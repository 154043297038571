import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44227084"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = { class: "title-column-symbol" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text-ellipsis" }
const _hoisted_5 = { class: "account-type" }
const _hoisted_6 = { class: "created-at" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!

  return (_openBlock(), _createBlock(_component_AfterLoginPage, {
    class: "member-list",
    onScrolledToBottom: _ctx.handleLoad
  }, {
    "top-part": _withCtx(() => [
      _createVNode(_component_BreadScrum, { paths: _ctx.dataBreadScrumLinks }, null, 8, ["paths"])
    ]),
    "right-part": _withCtx(() => [
      _createVNode(_component_TextButton, {
        onOnClick: _ctx.onDownloadCSV,
        text: _ctx.$t('company.textButtonDownloadCsv'),
        icon: require('icons/download.svg')
      }, null, 8, ["onOnClick", "text", "icon"])
    ]),
    "left-top-part": _withCtx(() => [
      _createVNode(_component_ValidationTextField, {
        "prefix-icon": require('icons/search-placeholder.svg'),
        "suffix-icon": require('icons/close.svg'),
        placeholder: _ctx.$t('company.memberPlaceHolderSearchBar'),
        "keep-error-space": false,
        name: "searchBar",
        value: _ctx.searchKeyword,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setFilterKeyword($event))),
        "is-delete-on-suffix": ""
      }, null, 8, ["prefix-icon", "suffix-icon", "placeholder", "value"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("company.memberTitleColumnSymbol")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("company.memberTitleColumnAccountType")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("company.memberTitleColumnCreatedAt")), 1)
      ])
    ]),
    "left-bottom-part": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberListData, (member, index) => {
        return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
          key: index,
          "head-icon": 
          member.profilePicture
            ? _ctx.avatarBaseUrl + member.profilePicture
            : require('icons/default-avatar.svg')
        ,
          "is-avatar-head": "",
          actions: _ctx.actionDropdown(member, false),
          "has-divider": ""
        }, {
          "title-primary": _withCtx(() => [
            _createElementVNode("p", {
              onClick: ($event: any) => (_ctx.clickTitle(member.id, false))
            }, _toDisplayString(`${member.lastName} ${member.firstName}`), 9, _hoisted_3),
            (member.phoneNumber)
              ? (_openBlock(), _createBlock(_component_Chip, {
                  key: 0,
                  text: member.phoneNumber,
                  dense: ""
                }, null, 8, ["text"]))
              : _createCommentVNode("", true)
          ]),
          "title-secondary": _withCtx(() => [
            _createElementVNode("p", _hoisted_4, _toDisplayString(member.email), 1)
          ]),
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Tag, {
                text: _ctx.$t(_ctx.getUserRoleName(member.role))
              }, null, 8, ["text"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(member.createdAt ? _ctx.formatDate(member.createdAt, "YYYY/MM/DD") : member.createdAt), 1)
            ])
          ]),
          _: 2
        }, 1032, ["head-icon", "actions"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["onScrolledToBottom"]))
}