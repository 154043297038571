
import { defineComponent, reactive, toRefs, computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import TextButton from "@/components/atomics/button/TextButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import Tag from "@/components/atomics/Tag.vue";
import Chip from "@/components/atomics/Chip.vue";
import useList from "@/lib/compositional-logic/useList";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import { downloadCSV } from "@/lib/utility/downloadFile";
import { formatDate } from "@/lib/utility/dateTime";
import { getUserRoleName } from "@/models/UserRole";
import { RepositoryFactory, CompanyRepository, CompanyMemberRepository } from "@/lib/https";


export default defineComponent({
  name: "CompanyMember",
  components: {
    TextButton,
    ValidationTextField,
    AfterLoginPage,
    ListItemActionDropdown,
    Tag,
    Chip,
    BreadScrum,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const companyId = route.params.companyID;
    const dataBreadScrumLinks = ref([
      { text: t("company.titleListCompanies"), to: "/company" },
      { text: "", to: "/company/" + companyId },
      { text: t("company.memberList"), to: "" },
    ]);

    const { getCompanySimple } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const getCompanyInfo = async () => {
      const response = await getCompanySimple(companyId.toString());
      if (!response) return;
      dataBreadScrumLinks.value[1].text = response.name
    };

    onMounted(getCompanyInfo);

    const state = reactive({
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
      searchKeyword: "",
    });

    const actionDropdown = (member: { id: string }, isInviting: any) => [{
      action: () => {
        router.push({
          path: `/member/` + member.id,
          query: { isInviting: isInviting }
        });
      },
      icon: "icons/details.svg",
      text: t("company.textButtonShowDetail"),
    }];

    const {
      getCompanyMemberList,
      downloadCompanyMemberCSV,
    } = RepositoryFactory.getRepository<CompanyMemberRepository>(CompanyMemberRepository);

    const {
      list: memberListData,
      loadMore,
      setFilterKeyword,
      keyword,
    } = useList({
      initialLoadList: async ({ pageSize, keyword }) =>
        await getCompanyMemberList(companyId as string, {page: 1, pageSize, keyword}),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getCompanyMemberList(companyId as string, {page, pageSize, keyword}),
      filterList: async ({ keyword, pageSize }) =>
        await getCompanyMemberList(companyId as string, {page:1, pageSize, keyword}),
    });
    const handleLoad = computed(() => loadMore);

    const onDownloadCSV = async () => {
      const response = await downloadCompanyMemberCSV(
        companyId as string,
        { isCsvExport: true, keyword: keyword.value }
      );
      downloadCSV(response, "member");
    };

    const clickTitle = (id: string, isInviting: any) => {
      router.push({ path: `/member/` + id, query: { isInviting: isInviting } });
    };

    return {
      ...toRefs(state),
      dataBreadScrumLinks,
      setFilterKeyword,
      actionDropdown,
      onDownloadCSV,
      memberListData,
      handleLoad,
      clickTitle,
      formatDate,
      getUserRoleName,
    };
  },
});
